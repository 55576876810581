






























































































































































































































































































































import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    Footer: () => import("@/components/Footer.vue"),
  },
})
export default class Home extends Vue {
  cycle = true;
  slides = ["First", "Second", "Third"];

  beforeCreate() {
    document.title = "API Center";
  }
}
