

























































































































import { Vue, Component } from "vue-property-decorator";

declare let chi: any;

@Component({
  components: {
    Footer: () => import("@/components/Footer.vue"),
    LandingPageAPI: () => import("@/components/LandingPageAPI.vue"),
  },
})
export default class SNOWAPIs extends Vue {
  beforeCreate() {
    document.title = "Service Now APIs | API Center";
  }

  mounted() {
    chi.tab(document.getElementById("snow-apis"));
  }
}
