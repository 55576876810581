import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "@/views/Index.vue";
import Home from "@/views/Home.vue";
import EISAPIs from "@/views/EIS-APIs.vue";
import SNOWAPIs from "@/views/SNOW-APIs.vue";
import SLEDAPIs from "@/views/SLED-APIs.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "eis",
        name: "EIS",
        component: EISAPIs,
      },
      {
        path: "service-now",
        name: "Service Now",
        component: SNOWAPIs,
      },
      {
        path: "sled",
        name: "SLED",
        component: SLEDAPIs,
      },
      // {
      //   path: "contact-us",
      //   name: "Contact Us",
      //   component: () => import("@/views/ContactUs.vue"),
      // },
    ],
  },
  {
    path: "/api/:domain/:module",
    name: "API's",
    component: () => import("@/views/APIs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
