















































































































































































































import { Vue, Component } from "vue-property-decorator";

declare let chi: any;

@Component({
  components: {
    Footer: () => import("@/components/Footer.vue"),
    Ribbon: () => import("@/components/Ribbon.vue"),
    LandingPageAPI: () => import("@/components/LandingPageAPI.vue"),
  },
})
export default class SLEDAPIs extends Vue {
  modules = [
    {
      icon: "business-deal",
      title: "Ordering",
      description: " Ordering APIs",
      specURL: "ordering.yaml",
    },
    {
      icon: "business-finance",
      title: "Billing",
      description: "Billing APIs",
      specURL: "",
    },
    {
      icon: "business-reports",
      title: "Invoicing",
      description: " Invoicing APIs",
      specURL: "",
    },
    {
      icon: "business-time",
      title: "Service Management",
      description: "Service Management APIs",
      specURL: "",
    },
    {
      icon: "people-us-network",
      title: "NG-911",
      description: "NG-911",
      specURL: "ng-911.yaml",
    },
  ];

  beforeCreate() {
    document.title = "SLED APIs | API Center";
  }

  mounted() {
    chi.tab(document.getElementById("sled-apis"));
  }

  contactUs() {
    window.open(
      "https://www.lumen.com/public-sector/contact-us.html",
      "_blank"
    );
  }
}
