












import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    Header: () => import("@/components/Header.vue"),
  },
})
export default class Index extends Vue {}
