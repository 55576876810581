



















































































































































































































import { Vue, Component } from "vue-property-decorator";

declare let chi: any;

@Component({
  components: {
    Footer: () => import("@/components/Footer.vue"),
    LandingPageAPI: () => import("@/components/LandingPageAPI.vue"),
    Ribbon: () => import("@/components/Ribbon.vue"),
  },
})
export default class EISAPIs extends Vue {
  modules = [
    {
      icon: "business-deal",
      title: "Ordering",
      description: " Ordering APIs",
      specURL: "ordering.yaml",
    },
    {
      icon: "business-finance",
      title: "Billing",
      description: "Billing APIs",
      specURL: "billing.yaml",
    },
    {
      icon: "business-checklist",
      title: "Inventory",
      description: " Inventory APIs",
      specURL: "",
    },
    {
      icon: "business-reports",
      title: "Invoicing",
      description: " Invoicing APIs",
      specURL: "invoicing.yaml",
    },
    {
      icon: "business-time",
      title: "Service Management",
      description: "Service Management APIs",
      specURL: "",
    },
  ];
  mdViewport = false;

  beforeCreate() {
    document.title = "Federal EIS APIs | API Center";
  }

  mounted() {
    chi.tab(document.getElementById("federal-eis-apis"));
    window.addEventListener("resize", this.onResize, { passive: true });
  }

  onResize() {
    this.mdViewport = window.innerWidth < 968;
  }

  contactUs() {
    window.open(
      "https://www.lumen.com/public-sector/contact-us.html",
      "_blank"
    );
  }
}
